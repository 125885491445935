import React, { useEffect } from "react";

import { Col, Row, Form, Image, Button } from "react-bootstrap";

import { useLocation, useHistory, Link } from "react-router-dom";
import useSeleccionServicio from "./hooks/useSeleccionServicio";

import { imgPath } from "../../constants/constants";

export default function SeleccionServicio() {
  const location = useLocation();
  let history = useHistory();

  const {
    items,
    kilometrajes,
    onChange,
    servicioSeleccionado,
    servicio,
    handleButtonClick,
  } = useSeleccionServicio();

  const kilometraje = kilometrajes();

  // eslint-disable-next-line no-unused-vars
  const product = location.state
    ? location.state.modeloSeleccionado
    : items.length > 0
    ? items[0]
    : history.push("/servicios");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //console.log(product, "product desde seleccion servicio");

  return (
    <>
      <Row className="m-0 p-0 mt-5 justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <h3 style={{ fontFamily: "Renault Bold" }}>Selecciona el service:</h3>
        </Col>
        <Col xs={12} md={6} className="pr-4">
          <Row className="m-0 p-0">
            <Col md={6}>
              <Form.Control as="select" onChange={onChange}>
                <option>"kms"</option>
                {kilometraje.map((kms, i) => (
                  <option value={kms} key={i}>
                    {kms}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5 mt-3">
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 6, offset: 1 }}
          className="text-left"
          style={{ fontSize: "13px" }}
        >
          Selecciona el kilometraje del service para visualizar el costo.
        </Col>
      </Row>

      <Row className="m-0 p-0 mb-5">
        <Col xs={12} md={6} className="text-md-center">
          <Row className="m-0 p-0 justify-content-center">
            <Col
              xs={12}
              md={8}
              style={{
                backgroundColor: "black",
                color: "white",
                height: "55px",
                borderBottom: "3px solid lightGrey",
              }}
              className="d-flex align-items-center"
            >
              SELECCIONE UNA OPCION
            </Col>
          </Row>
          {servicioSeleccionado && (
            <Row className="m-0 p-0 justify-content-center">
              <Col
                xs={12}
                md={8}
                style={{
                  backgroundColor: "lightGrey",
                  color: "black",
                  height: "60px",
                }}
                className="text-left d-flex align-items-center"
              >
                <div style={{ fontSize: "18px" }}>
                  {servicioSeleccionado
                    ? "Costo por el servicio de " + servicioSeleccionado
                    : "seleccione un kilometraje"}
                </div>
              </Col>
              <Col
                xs={12}
                md={8}
                style={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  borderBottom: "3px solid lightGrey",
                  fontSize: "20px",
                  paddingBottom: "20px",
                }}
              >
                {servicio &&
                  servicio.map((item, i) => (
                    <>
                      <Row className="m-0 p-0 text-left mt-3" key={item.id}>
                        <Col>{item.servicio}</Col>
                      </Row>
                      <Row className="m-0 p-0">
                        <Col xs={12} className="d-flex align-items-center mt-2">
                          <b style={{ fontSize: "12px" }}>
                            Precio sin impuestos nacionales: &nbsp;
                            {servicio[0] !== undefined &&
                              Intl.NumberFormat("es-AR", {
                                style: "currency",
                                currency: "ARS",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(parseFloat(item.precio / 1.21))}
                          </b>
                        </Col>
                        <Col
                          xs={6}
                          className="text-left d-flex align-items-center"
                        >
                          <b>
                            Total: &nbsp;
                            {Intl.NumberFormat("es-AR", {
                              style: "currency",
                              currency: "ARS",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(parseFloat(item.precio))}
                          </b>
                        </Col>
                        <Col
                          xs={6}
                          className="text-center d-flex align-items-center justify-content-center"
                        >
                          <Link
                            to={{
                              pathname: "/servicios/seleccionSucursal",
                              state: {
                                servicioSeleccionado: servicio && {
                                  ...servicio[i],
                                  origen: "servicios",
                                },
                              },
                            }}
                            style={{ color: "black" }}
                          >
                            <Button
                              size="sm"
                              variant="outline-light"
                              style={{
                                border: "1px solid black",
                                fontWeight: "bold",
                                fontFamily: "Renault Bold",
                                borderRadius: "0px",
                                color: "black",
                              }}
                              onClick={() => handleButtonClick(servicio[i])}
                            >
                              continuar
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  ))}
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={12} md={6} className="text-center text-md-left mt-4">
          {product && (
            <Image src={imgPath + product.foto} style={{ maxWidth: "50%" }} />
          )}

          <Row className="m-0 p-0">
            <Col xs={12} md={6} className="text-center">
              <b>{product && product.modelo}</b>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 p-0 my-5 justify-content-center">
        <Col xs={10} style={{ fontSize: "12px" }}>
          * Operaciones adicionales habituales. Para algun vehículo las
          operaciones y cambios pueden diferir según lo que figure en su manual
          de garantía y mantenimiento.
        </Col>
      </Row>
    </>
  );
}
